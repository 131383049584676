<template>
  <v-container class="pa-0">
    <v-select
      outlined
      v-model="grupoId"
      :items="grupos"
      item-text="grupoUsuNombre"
      item-value="grupoUsuId"
      label="Grupo"
      @change="onGrupoChange"
    ></v-select>
    <v-container class="pa-0">
      <v-icon :class="{ rotate: openAll }">mdi-chevron-down</v-icon>
      <a @click="expandCollapse">{{
        openAll ? "Colapsar todos" : "Expandir todos"
      }}</a>
    </v-container>
    <div class="text-center mt-4" v-if="loading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-container v-else class="pa-0">
      <v-treeview
        hoverable
        open-on-click
        v-if="permisos"
        selectable
        selection-type="independent"
        selected-color="primary"
        item-disabled="locked"
        :items="permisos.items"
        v-model="selected"
        @input="onChangeSelected($event)"
        ref="tree"
      >
        <template v-slot:prepend="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="item.type == 1"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-alpha-m-box
              </v-icon>
              <v-icon
                v-if="item.type == 2"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-alpha-o-box
              </v-icon>
              <v-icon
                v-if="item.type == 3"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-alpha-p-box
              </v-icon>
            </template>
            <span v-if="item.type == 1">Opción de menú</span>
            <span v-if="item.type == 2">Opción de pantalla</span>
            <span v-if="item.type == 3">Permiso de usuario</span>
          </v-tooltip>
        </template>
      </v-treeview>
    </v-container>
    <v-row>
      <v-col cols="12" align="right">
        <v-btn color="primary"
          @click="save"
          :loading="btnIsLoading"
          :disabled="selected.length == 0"
          >Guardar</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import helper from "@/utils/helpers";

export default {
  data() {
    return {
      openAll: false,
      loading: false,
      btnIsLoading: false,
      grupoId: 0,
      selected: [],
      tmpPermisosDTO: [],
      flattenedArray: [],
      selectedBeforeUpdate: [],
      firstLoad: true,
      defaultErrorMsg: enums.messages.SYSTEM_ERROR,
    };
  },
  async mounted() {
    this.loading = true;
    await this.getGrupos();
    this.grupoId = this.grupos[0].grupoUsuId;
    await this.getPermisos(this.grupoId);
    this.selected = this.permisos.selected;
    this.flatArray();
    this.filterNewSelection([...this.selected]);
    this.firstLoad = false;
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      grupos: "user/grupoUsuarios",
      permisos: "AdministracionSistema/dataRowPermisos",
    }),
  },
  methods: {
    ...mapActions({
      getGrupos: "user/grupoUsuarios",
      grabarPermisos: "AdministracionSistema/grabarPermisos",
      getPermisos: "AdministracionSistema/getPermisos",
      setAlert: "user/setAlert",
    }),
    expandCollapse() {
      this.openAll = !this.openAll;
      if (this.openAll) this.$refs.tree.updateAll(true);
      else this.$refs.tree.updateAll(false);
    },
    async onGrupoChange(grupoId) {
      this.loading = true;
      this.grupoId = grupoId;
      this.openAll = false;
      await this.getPermisos(grupoId);
      this.selected = this.permisos.selected;
      this.selectedBeforeUpdate = [];
      this.firstLoad = true;
      this.filterNewSelection([...this.selected]);
      this.selectedBeforeUpdate = [...this.selected];
      this.firstLoad = false;
      this.loading = false;
    },
    flatArray() {
      // creo un array plano para facilitar la busqueda y filtrado
      this.flattenedArray = helper.flatArray(this.permisos.items);
    },
    onChangeSelected(selected) {
      // para cdo se deselecciona un nodo
      if (selected.length < this.selectedBeforeUpdate.length) {
        let removedNode = this.selectedBeforeUpdate.find(x => !selected.includes(x));
        removedNode && this.unselectChildren(removedNode);
      }
      else {
        this.filterNewSelection(selected);
      }
      this.selectedBeforeUpdate = selected;
    },
    filterNewSelection(actives) {
      // se agrega un nodo
      let newNodes = actives.filter(x => !this.selectedBeforeUpdate.includes(x));
      if (newNodes && newNodes.length) {
        newNodes.forEach(node => {
          this.handleSelected(node);
        });
      }
      this.selectedBeforeUpdate = [...this.selected];
    },
    handleSelected(node) {
      let item = this.flattenedArray.find((x) => x.id === node);
      if (item) {
        this.includeParents(item);
        !this.firstLoad && this.includeChildren(item);
      }
    },
    includeParents(node) {
      const parentId = node.parentId;
      if (parentId > 0) {
        this.addToSelected(parentId);
        let tmp = this.flattenedArray.find((x) => x.id === parentId);
        if (tmp && tmp.parentId > 0) {
          this.includeParents(tmp);
        }
      }
    },
    includeChildren(node) {
      if (node.children.length) {
        node.children.forEach(child => {
          this.selected.push(child.id);
          if (child.children.length) {
            this.includeChildren(child)
          }
        })
      }
    },
    unselectChildren(nodeId) {
      // si se quita un nodo, hay que verificar si tiene children para deseleccionarlos tambien
      let tmp = this.flattenedArray.find(x => x.id === nodeId);
      if (tmp && tmp.children.length) {
        tmp.children.forEach(child => {
          this.selected = this.selected.filter(x => x !== child.id);
          if (child.children.length) {
            this.unselectChildren(child.id)
          }
        })
      }
    },
    addToSelected(nodeId) {
      // agregar nodo a la lista de seleccionados
      !this.selected.includes(nodeId) && this.selected.push(nodeId);
    },
    async save() {
      const vm = this;
      vm.selected.forEach(node => {
        vm.tmpPermisosDTO.push({ optionId: node, sysgId: vm.grupoId });
      })
      try {
        this.btnIsLoading = true;
        await this.grabarPermisos(vm.tmpPermisosDTO);
      } catch (error) {
        this.setAlert({ type: "error", message: this.defaultErrorMsg });
        this.btnIsLoading = false;
      }
      this.btnIsLoading = false;
      this.tmpPermisosDTO = [];
    },
  },
};
</script>
<style scoped>
.rotate {
  transform: rotate(-180deg);
}
</style>