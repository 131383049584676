<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
      <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <page-header :title="title" class="pt-5 pb-2"></page-header>
        
        <v-card>
          <v-card-title>
            {{ this.cardTitle }}
          </v-card-title>
          <v-card-text>
            <Permisos />
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0 "
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Permisos from "@/components/modules/administracion-sistema/Permisos.vue";
import PageHeader from "@/components/ui/PageHeader.vue";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import Ayuda from "@/components/shared/Ayuda.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "ConfiguracionPermisos",
  components: {
    Permisos,
    PageHeader,
    GoBackBtn,
    Ayuda
  },
  data() {
    return {
      title: enums.titles.CONFIGURACION_PERMISOS,
      cardTitle: enums.titles.CONFIGURACION_PERMISOS_CARD_TITLE,
      optionCode: enums.webSiteOptions.GESTION_PERMISOS,
      routeToGo: "ConfiguracionAdmSistema",
      showHelp: false,
      showIcon: true,
      showExpand: false
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
  },
  methods: {
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>
